import React from "react"
import ListArticles from "../components/articles/ListArticles"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import PageIntro from "../components/PageIntro"
import SEO from "../components/seo"

export default function ArtiklarPage() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 119 }) {
        content
        sidinfo {
          introRubrikSida
        }
        title
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)
  const { content, featuredImage, title, sidinfo } = data.wpPage
  return (
    <Layout>
      <SEO title="Skriver" />
      <PageIntro text={sidinfo.introRubrikSida || title} />
      <div className="single-page-inner">
        <div className="small-container">
          <div className="flex flex-col gap-8">
            <div className="flex-1 space-y-8">
              {/* <PageHeader text={title} /> */}
              <div
                className="wp-content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
            <ListArticles />
          </div>
        </div>
      </div>
    </Layout>
  )
}
