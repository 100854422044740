import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Link from "../common/Link"
import { getLongDate } from "../../utils/getLongDate"

export default function ListArticles({ context }) {
  const data = useStaticQuery(graphql`
    {
      allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          excerpt
          content
          title
          uri
          date
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)
  const articles = data.allWpPost.nodes
  return (
    <div className="grid gap-8">
      {articles.map(article => {
        return <Article key={article.id} article={article} />
      })}
    </div>
  )
}

function Article({ article }) {
  const { title, date, uri } = article
  const newsDate = getLongDate(date, "Do MMMM, YYYY")
  return (
    <div className="">
      <Link to={uri}>
        <h2 className="">{title}</h2>
      </Link>
      <p className="text-xl">{newsDate}</p>
    </div>
  )
}
